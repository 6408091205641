/* Utilitiy classes */

.spin {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
}

.d-flex {
  display: flex;
}

.flex-grid .flex-grid-child {
  padding: 0 12px;
}

.align-center-v {
  display: flex;
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.line-height-0 {
  line-height: 0;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.status {
  display: inline !important;
  font-size: 12px;
  font-weight: 500;
  background-color: #868eae10;
  padding: 4.5px 11.85px;
  border-radius: 15px;
}

.status.Success {
  background-color: #20c99710;
  color: #20c997;
}

.status.warning {
  background-color: #fa8b0c10;
  color: #fa8b0c;
}

.status.error {
  background-color: #ff4d4f10;
  color: #ff4d4f;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/* spacing classes */

.m-0 {
  margin: 0 !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-0 {
  padding-left: 0 !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.pr-0 {
  padding-right: 0 !important;
}

/* default card style */

.ant-card {
  box-shadow: 0 5px 20px #9299b803;
}

.ant-card-body {
  padding: 25px !important;
}

.ant-card-head {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.ant-card-head-title span {
  display: inline-block;
  margin-left: 15px;
  font-size: 11px;
  font-weight: 500;
  color: #868eae;
}


.ant-card-head .ant-card-extra {
  display: flex;
  align-items: center;
}

.ant-card-head .ant-card-extra a {
  color: #868eae;
}

.ant-card-extra .ant-dropdown-trigger {
  line-height: 0;
  order: 1;
  margin-left: 20px;
}

.sDash_unresizable {
  resize: none;
}

/* ant radio group */

.ant-radio-button-wrapper-checked {
  color: #fff !important;
}

/* card nav */

.card-nav ul {
  list-style: none;
  display: flex;
  margin: 0 -8px !important;
}

.card-nav ul li {
  margin: 0 8px !important;
  position: relative;
}

.card-nav ul li a {
  font-weight: 500;
  color: #868eae;
  font-size: 12px;
}

.card-nav ul li.active a {
  color: #008455;
  font-weight: 500;
}

.card-nav ul li.active:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  background: #008455;
  left: 0;
  bottom: -19px;
}

/* chart growth upward */

.growth-downward p,
.growth-upward p {
  font-size: 14px;
  color: #868eae;
  margin: 0;
}

.growth-downward h1 sub svg,
.growth-upward h1 sub svg {
  position: relative;
  top: 2px;
  font-size: 14px;
  font-weight: 600;
  left: 5px;
  bottom: 0;
}

/*
.growth-downward h1, .growth-upward h1 {
  font-size: 22px;
  margin: 6px 0 0;
} */

.growth-downward h1 sub {
  color: #ff4d4f;
}

.growth-upward h1 sub {
  color: #20c997;
}

/* Chart */

.chart-label {
  display: flex;
}

.chart-label .chart-label__single {
  align-items: center;
}

.chart-label .chart-label__single:not(:last-child) {
  margin-right: 40px;
}

.chart-label .chart-label__single p {
  margin: 0;
  color: #868eae;
}

/* revenue doughnut */

.revenue-doughnut {
  display: flex;
  justify-content: center;
}

.revenue-doughnut>div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.revenue-doughnut .rd-labels {
  width: 100%;
  margin-top: 30px;
}

.revenue-chat-percentage span {
  display: block;
}

.revenue-chat-percentage span:not(:last-child) {
  margin-bottom: 15px;
}

/* progressbars */

.ant-progress {
  display: inline-flex !important;
  align-items: center;
}

.ant-progress>div {
  display: flex;
  flex-direction: column;
}

.ant-progress .ant-progress-outer {
  margin-right: 0 !important;
  padding-right: 0 !important;
}

.revenue-chat-percentage span {
  display: block;
  font-size: 13px;
  color: #868eae;
}

.revenue-chat-percentage span:not(:last-child) {
  margin-bottom: 20px;
}

.ant-progress-status-warning .ant-progress-bg {
  background: #fa8b0c;
}

/* progress bars */

.progress-primary .ant-progress-bg {
  background: #008455;
}

.progress-secondary .ant-progress-bg {
  background: #ff69a5;
}

.progress-success .ant-progress-bg {
  background: #20c997;
}

.progress-success .progress-info .ant-progress-bg {
  background: #2c99ff;
}

.progress-warning .ant-progress-bg {
  background: #fa8b0c;
}

.progress-danger .ant-progress-bg {
  background: #ff4d4f;
}

/* color classes */

.color-primary {
  color: #008455;
}

.color-secondary {
  color: #ff69a5;
}

.color-info {
  color: #2c99ff;
}

.color-warning {
  color: #fa8b0c;
}

.color-success {
  color: #20c997;
}

.color-danger {
  color: #ff4d4f;
}

.color-dark {
  color: #272b41;
}

.color-error {
  color: #f5222d;
}

.color-gray {
  color: #5a5f7d;
}

/* Button Styles */

.ant-btn.ant-btn-light:focus {
  background: #fff;
  color: #5a5f7d;
}

.button-example .ant-btn {
  margin: 4px;
}

.button-example .ant-btn-group .ant-btn {
  margin: 0;
}

.ant-btn-white {
  color: #5a5f7d !important;
  border-color: #e3e6ef !important;
}

.ant-btn-white:hover {
  color: #008455 !important;
  background-color: #fff !important;
  border-color: #fff;
}

.ant-btn-white:focus {
  background-color: transparent !important;
}

.ant-btn-link {
  background-color: #fff;
  border-color: #fff;
  color: #008455;
}

.ant-btn-link:hover {
  border-color: #fff;
  background: #fff;
}

.ant-btn-light,
.ant-btn-light:hover,
.ant-btn-link:hover,
.ant-btn-dashed {
  color: #5a5f7d !important;
}

.ant-btn-light {
  border-color: #e3e6ef;
  background: #f4f5f7 !important;
}

.ant-btn-light.btn-outlined {
  background: transparent !important;
}

.ant-btn-light.btn-transparent {
  background: #f4f5f715;
}

.ant-btn-dashed {
  border-width: 1px !important;
}

.ant-btn-dashed:hover {
  color: #008455;
  border: 1px dashed #008455;
}

.ant-btn-primary[disabled] {
  color: #fff;
  background: #008455;
}

.ant-btn-light[disabled] {
  background: #fff;
}

.ant-btn-round.ant-btn-sm {
  height: 38px;
}

.ant-btn-white[disabled] {
  background-color: transparent;
  opacity: 0.6;
}

.ant-btn-white[disabled]:hover {
  color: #5a5f7d !important;
}

.ant-btn-primary[disabled]:hover {
  color: #fff !important;
  background: #008455 !important;
}

.btn-icon {
  padding: 0 13px;
}

.btn-inc,
.btn-dec {
  height: 38px;
  width: 38px;
  font-size: 20px;
  padding: 0 12px !important;
  border-radius: 10px !important;
  border: 0 none;
}

.btn-inc:hover,
.btn-dec:hover {
  background: #00845510 !important;
  border: 0 none !important;
}

.btn-inc:hover i,
.btn-inc:hover svg,
.btn-dec:hover i,
.btn-dec:hover svg {
  color: #008455;
}

/* input styles */

.ant-form-item-label>label {
  font-weight: 500;
}

.ant-picker-input>input::placeholder {
  color: #adb4d2 !important;
}

/* Calendar Styles */

.ant-picker-calendar-header .ant-select-selector {
  height: 32px !important;
}

.ant-picker-calendar-header .ant-select-selection-search-input {
  height: 30px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 30px !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #fff !important;
  color: #008455 !important;
}

/* pagination */

.ant-pagination .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 32px !important;
}

.ant-pagination-item-active {
  background-color: #008455 !important;
}

.ant-pagination-item-active a {
  color: #fff !important;
}

.ant-pagination .ant-pagination-options .ant-select-selection-item {
  font-size: 13px;
  line-height: 30px !important;
}

.ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper {
  height: 30px;
  line-height: 30px;
}

.ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper input {
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-jump-prev,
.ant-pagination .ant-pagination-jump-next,
.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-options .ant-select-selector {
  border: 1px solid #f1f2f6 !important;
  background-color: #fff;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-ellipsis,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-ellipsis {
  color: #5a5f7d !important;
  line-height: 2.6;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-link {
  display: block;
}

.ant-pagination-prev,
.ant-pagination-next {
  line-height: 28px !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: 0 none !important;
}

.ant-pagination .ant-pagination-item a {
  color: #5a5f7d;
}

.ant-pagination .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 30px !important;
  color: #5a5f7d;
}

.ant-pagination .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 100% !important;
}

.ant-pagination {
  margin: -4px -12px !important;
}

.ant-pagination-item,
.ant-pagination-options,
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next,
.ant-pagination-next {
  margin: 4px !important;
}

.ant-table-pagination {
  margin-top: 30px !important;
}

/* Wizard Modal */
.submission-successModal {
  text-align: center;
}

.submission-successModal .icon-success {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 1px solid #20c997;
}

.submission-successModal p {
  margin-bottom: 0;
}

.submission-successModal .icon-success svg {
  color: #20c997;
}

.submission-successModal .submission-action {
  margin: 30px -5px -5px -5px;
}

.submission-successModal .submission-action button {
  margin: 5px;
  height: 38px;
}

/* Tree Select */

.ant-tree-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 42px !important;
  line-height: 40px;
}

.ant-tree-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 100% !important;
}

.ant-tree-select.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px !important;
}

/* radio style */

.ant-radio-checked .ant-radio-inner {
  border-width: 5px !important;
}

.ant-radio-inner::after {
  content: none !important;
}

/* Statistics */

.ant-statistic .ant-statistic-title {
  color: #9299b8;
}

.ant-statistic .ant-statistic-content span {
  font-size: 20px;
}

/* Steps */

.steps-action.justify-content-center {
  justify-content: center !important;
}

/* rate */

.ant-rate-star.ant-rate-star-zero span svg {
  color: #c6d0dc;
}

.ant-rate-star:not(:last-child) {
  margin-right: 2px !important;
}

.ant-rate-text {
  color: #5a5f7d;
}

/* result */

.ant-result-icon {
  margin-bottom: 20px !important;
}

.ant-result-title {
  font-weight: 500;
  margin-bottom: 10px;
}

.ant-result-extra {
  height: 34px;
  padding: 4px 10.72px;
}

.ant-result-content .ant-typography strong {
  font-weight: 500;
}

.ant-result-content .ant-typography:last-child {
  margin-bottom: 0;
}

/* form select */

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 38px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 33px !important;
}

.ant-select-multiple .ant-select-selector {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.sDash_fullwidth-select {
  width: 100%;
}

/* Nasted Comments Styles */

.nested-comment-wrapper .comment-title {
  font-size: 12px;
  padding-bottom: 10px;
  margin-bottom: 22px;
  border-bottom: 1px solid #e3e6ef;
}

/* calendar style */

.events {
  list-style: none;
  margin: 0;
  padding: 0;
}

.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}

.notes-month {
  text-align: center;
  font-size: 28px;
}

.notes-month section {
  font-size: 28px;
}

/* Breadcrumb demo */

.demo {
  margin: 16px;
}

.demo-nav {
  height: 30px;
  line-height: 30px;
  margin-bottom: 16px;
  background: #f8f8f8;
}

.demo-nav a {
  line-height: 30px;
  padding: 0 8px;
}

.app-list {
  margin-top: 16px;
}

/* Modal */

.ant-modal .ant-modal-content {
  border-radius: 10px;
}

.ant-modal .ant-modal-header {
  border-radius: 10px 10px 0 0;
}

.ant-modal-body p:last-child {
  margin-bottom: 0;
}

.project-modal {
  padding: 5px !important;
}

.project-modal .projects-members {
  margin-top: -12px;
}

.project-modal .ant-form-item-control-input {
  margin-top: 10px;
  min-height: auto;
}

.project-modal .ant-select-selector {
  border-color: #e3e6ef;
}

.project-modal-footer {
  padding: 10px !important;
  text-align: left !important;
}

.sDash_addTask-modal {
  max-width: 390px;
}

.sDash_addTask-modal .ant-modal-header {
  border-bottom: 0 none;
  padding-bottom: 0 !important;
}

.sDash_addTask-modal .ant-modal-header .ant-modal-title {
  font-size: 16px;
  font-weight: 500;
  color: #272B41;
}

.sDash_addTask-modal .sDash_addTask-modal-inner .ant-form-item {
  margin-bottom: 15px;
}

.sDash_addTask-modal .ant-modal-header .ant-modal-close-x svg {
  color: #9299B8;
}

.sDash_addTask-modal .ant-form-item-control-input {
  min-height: 36px;
}

.sDash_addTask-modal input::placeholder,
.sDash_addTask-modal textarea::placeholder {
  color: #9299B8;
}

.sDash_addTask-modal input,
.sDash_addTask-modal textarea {
  padding: 6px 20px;
  border-radius: 5px;
}

.sDash_addTask-modal textarea {
  resize: none;
  min-height: 125px;
}

.sDash_addTask-modal .sDash-modal-actions {
  display: flex;
  justify-content: flex-end;
  margin: -6px;
}

.sDash_addTask-modal .sDash-modal-actions button {
  font-size: 14px;
  margin: 6px;
  height: 38px;
  border-radius: 5px;
}

/* slider */

.ant-slider-handle {
  margin-top: -6px !important;
}

/* tags */

.ant-tag {
  font-weight: 600;
  padding: 0 9.5px;
}

/* tabs */

.ant-tabs-tab span {
  display: flex;
  align-items: center;
}

/* list */

.ant-list-bordered {
  border-color: #e3e6ef !important;
}

.ant-list-item-meta {
  padding: 10px 24px;
  border-bottom: 1px solid #f1f2f6;
}

/* popover */

.ant-popover {
  position: fixed;
  z-index: 99999;
}

.ant-popover-inner {
  padding: 15px;
  box-shadow: 0 5px 20px #9299b820;
  border-radius: 0 0 6px 6px;
}

.ant-popover-inner .ant-popover-title {
  padding: 5px 10px 10px;
}

.ant-popover-inner .ant-popover-inner-content a {
  color: #5a5f7d;
}

/* Drawer */

.ant-drawer {
  z-index: 99999;
}

/* Select Dropdwon */

.ant-select-dropdown {
  padding: 18px 0 !important;
  box-shadow: 0 5px 20px #9299b820 !important;
  border-radius: 0 0 6px 6px !important;
}

.ant-select-item {
  min-height: 20px !important;
  padding: 4px 12px !important;
}

.ant-select-item-group {
  color: #9299b8;
}

.ant-select-item.ant-select-item-option-grouped {
  padding-left: 25px !important;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-active {
  background: #00845505;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-selected .ant-select-item-option-content {
  padding-left: 10px;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-selected {
  color: #008455;
  background: #00845506;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-selected .ant-select-item-option-content {
  color: #008455;
  font-weight: 500;
}

.ant-select-dropdown .ant-select-item .ant-select-item-option-content {
  transition: 0.3s;
  color: #5a5f7d;
}


/* mail props dropdown */

.mail-props {
  padding: 5px 25px;
  border: 0 none;
  background: #fff;
  /* box-shadow: 0 5px 40px rgba(146, 153, 184, 0.25); */
}

.mail-props li {
  display: flex;
  margin-bottom: 12px;
}

.mail-props li span:first-child {
  margin-right: 50px;
  min-width: 40px;
}

.mail-props li span:last-child {
  color: #5a5f7d;
}

.mail-props li:last-child {
  margin-bottom: 0;
}

.mail-props li span {
  color: #9299b8;
}

/* Basic Dropdwon */

.ant-dropdown {
  box-shadow: 0 5px 30px #9299b820 !important;
}

.ant-dropdown.wide-dropdwon {
  min-width: 140px !important;
}

.ant-dropdown.wide-dropdwon.kanbanCard-more {
  min-width: 220px !important;
  box-shadow: 0 17px 20px #9299B820;
}

.ant-dropdown.wide-dropdwon.kanbanCard-more a {
  padding: 10px 24px;
}

.ant-dropdown-menu {
  min-width: 200px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0;
}

.ant-dropdown-menu li {
  color: #5a5f7d;
  padding: 5px 25px;
}

.ant-dropdown-menu li:hover {
  background-color: #00845505;
}

.atbd-top-dropdwon .atbd-top-dropdwon__title {
  background: #f4f5f7;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  min-height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.atbd-top-dropdwon {
  width: 340px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav {
  height: 260px;
  overflow: hidden;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav.notification-list {
  padding: 0 10px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li {
  width: 100%;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a {
  padding: 13px 10px;
  position: relative;
  width: 100%;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li:last-child {
  margin: 0;
}

.atbd-top-dropdwon a.btn-seeAll {
  position: relative;
  width: calc(100% + 30px);
  left: -15px;
  right: -15px;
  height: calc(100% + 15px);
  bottom: -15px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: #008455;
  padding: 15px 0;
  border-radius: 0 0 6px 6px;
  background: #fff;
}

.atbd-top-dropdwon a.btn-seeAll:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 -15px 20px #9299b808;
  z-index: 1;
  content: '';
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a.btn-seeAll:hover:after {
  box-shadow: 0 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a:hover {
  background: #fff;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a:hover:after {
  opacity: 1;
  visibility: visible;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a:after {
  position: absolute;
  left: -15px;
  right: -15px;
  top: 0;
  width: calc(100% + 30px);
  height: 100%;
  box-shadow: 0 15px 50px #9299b820;
  z-index: 1;
  content: '';
  opacity: 0;
  visibility: hidden;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-icon {
  width: 39.2px;
  height: 32px;
  margin-right: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-icon.bg-primary {
  background: #00845515;
  color: #008455;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-icon.bg-secondary {
  background: #ff69a515;
  color: #ff69a5;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-icon svg {
  width: 18px;
  height: 18px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.atbd-top-dropdwon .notification-text h1 {
  font-size: 14px;
  font-weight: 400;
  color: #5a5f7d;
  margin-bottom: 4px;
}

.atbd-top-dropdwon .notification-text h1 span {
  color: #008455;
  font-weight: 500;
  padding-left: 0;
}

.atbd-top-dropdwon .notification-text p {
  font-size: 12px;
  color: #adb4d2;
  margin-bottom: 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content img {
  max-width: 40px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption {
  margin: -4px 15px 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption .ant-badge-count {
  font-size: 8px;
  min-width: 16px;
  width: 16px;
  height: 16px;
  line-height: 6px;
  border-radius: 8px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption p.ant-scroll-number-only-unit.current {
  height: 15px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption h1 span {
  font-size: 12px;
  font-weight: 400;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption p {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.add-file-dropdown a {
  font-size: 14px;
  font-weight: 400;
}

.add-file-dropdown a svg {
  width: 14px;
}

.folder-dropdown {
  min-width: 200px !important;
}

/* Tooltip Styles */

.ant-tooltip .ant-tooltip-inner {
  min-height: 40px;
  padding: 6px 20px;
  font-size: 14px;
  color: #5a5f7d;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  border: 1px solid #f1f2f6;
  box-shadow: 0 8px 15px #9299b815;
}

.ant-tooltip .ant-tooltip-arrow {
  /* position: relative; */
}

.ant-tooltip .ant-tooltip-arrow:after {
  position: absolute;
  left: 50%;
  top: 12px;
  transform: translateX(-50%);
  width: 13px;
  height: 13px;
  background: #fff;
  content: '';
}

.ant-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content {
  width: 10px;
  height: 10px;
  background: #fff;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow .ant-tooltip-arrow-content {
  border: 1px solid #f1f2f6;
  box-shadow: 0 0;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  top: -4.071068px;
}

/* Badge Styles */

.ant-badge.badge-success .ant-badge-count {
  background: #20c997;
}

.badge {
  font-size: 11px;
  font-weight: 500;
  padding: 0 6.5px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.badge.badge-primary {
  background: #00845510;
  color: #008455;
}

/* Cascade Styles */

.custom-cascade-render .ant-cascader-picker {
  width: 100%;
}

.ant-cascader-picker input::placeholder {
  color: #adb4d2;
}

/* Comment Styles */

.ant-comment-actions li {
  margin-bottom: 0 !important;
}

/* Radio Vertical Button */

.ant-radio-vertical .ant-radio-wrapper {
  display: block;
}

.ant-radio-vertical .ant-radio-wrapper:not(:last-child) {
  margin-bottom: 14px;
}

/* Select Tree */

.ant-select-tree-list .ant-select-tree-node-content-wrapper:hover {
  background-color: #bae7ff !important;
}

.ant-select-tree-list .ant-select-arrow svg {
  font-size: 10px;
}

.ant-tree-select.ant-select-multiple .ant-select-selection-item {
  border: 0 none;
  border-radius: 3px;
  background: #f4f5f7;
  color: #5a5f7d;
  font-weight: 500;
}

/* Ant Switch */

.ant-switch:after {
  width: 14px !important;
  height: 14px !important;
}

.ant-switch-small:after {
  width: 10px !important;
  height: 10px !important;
}

/* Time Picker */

.ant-picker {
  min-width: 250px;
}

/* Input Affix */

.ant-input-affix-wrapper>input.ant-input {
  padding-left: 5px;
}

.ant-input-affix-wrapper .ant-input-prefix svg {
  color: #e3e6ef;
}

/* Space Item */

.ant-space-item .ant-btn span {
  font-size: 14px;
}

/* Pop confirm */

.pop-confirm .ant-btn {
  max-width: 90px;
  padding: 0px 35.5px;
  height: 44px;
}

.pop-confirm-top {
  margin-bottom: 10px;
}

.pop-confirm-bottom {
  margin-top: 10px;
}

.pop-confirm-top .ant-btn:not(:last-child),
.pop-confirm-bottom .ant-btn:not(:last-child) {
  margin-right: 10px;
}

.pop-confirm-left .ant-btn:not(:last-child),
.pop-confirm-right .ant-btn:not(:last-child) {
  margin-bottom: 10px;
}

/* Ant Upload */

.ant-upload .ant-btn {
  font-size: 14px;
  border-radius: 5px;
}

.ant-upload.ant-upload-select-picture-card {
  border-color: #e3e6ef !important;
  border-radius: 5px !important;
  background-color: #f8f9fb !important;
}

.ant-upload.ant-upload-select-picture-card .anticon {
  margin-bottom: 8px;
}

.ant-upload .anticon svg {
  color: #9299b8;
}

.ant-upload-list {
  margin-top: 10px !important;
}

.sDash_upload-basic .ant-upload.ant-upload-select {
  width: 100%;
  border: 1px solid #E3E6EF;
  border-radius: 4px;
}

.sDash_upload-basic .ant-upload.ant-upload-select .ant-upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sDash_upload-basic .sDash_upload-text {
  font-size: 14px;
  color: #9299B8;
  padding-left: 15px;
}

html[dir='rtl'] .sDash_upload-basic .sDash_upload-text {
  padding-left: 0px;
  padding-right: 15px;
}

.sDash_upload-basic .sDash_upload-browse {
  font-size: 14px;
  color: #9299B8;
  display: inline-block;
  padding: 14px 23px;
  border-left: 1px solid #E3E6EF;
}

html[dir='rtl'] .sDash_upload-basic .sDash_upload-browse {
  border-left: 0 none;
  border-right: 1px solid #E3E6EF;
}

.ant-card.sDash_upload-form .ant-card-body {
  padding-bottom: 15px !important;
}

/* Ant Picker */

.ant-picker {
  border-color: #e3e6ef !important;
}

/* Ant Dropdown */

.ant-dropdown {
  box-shadow: 0 5px 20px #9299b820;
  background: #fff;
  border-radius: 6px;
  padding: 15px 0 !important;
  border: 0 none;
}

.ant-dropdown a {
  display: flex;
  align-items: center;
  padding: 8px 24px;
  font-weight: 400;
  color: #5a5f7d;
}

.ant-dropdown a i,
.ant-dropdown a svg,
.ant-dropdown a img {
  margin-right: 8px;
}

.ant-dropdown div {
  box-shadow: 0 0;
  border-radius: 5px;
}

/* Picker Under Input */

.ant-form-item-control-input .ant-picker {
  padding: 0 12px 0 0;
}

/* Leaflet COntainer */

.leaflet-container {
  z-index: 0;
}

/* Table Bordered*/

.table-bordered .ant-table-tbody>tr.ant-table-row:hover>td {
  background: #f8f9fb !important;
}

.table-bordered .ant-table-thead>tr>th {
  background: #fff;
  border-top: 1px solid #f1f2f6;
}

.table-bordered .ant-table-tbody>tr>td {
  border-color: #f1f2f6 !important;
}

.table-bordered .ant-table-thead tr th,
.table-bordered .ant-table-tbody tr td {
  padding: 16px 25px;
}

.table-bordered .ant-table-thead tr th:last-child,
.table-bordered .ant-table-tbody tr td:last-child {
  text-align: right;
}

/* Full Width Table */

.full-width-table .ant-card-body {
  padding: 0 !important;
}

.full-width-table .ant-table {
  border-radius: 10px !important;
}

.full-width-table .top-seller-table {
  min-height: 406px;
}

.full-width-table .top-seller-table .ant-table-content .ant-table-cell {
  white-space: normal;
  padding: 16px 15px;
}

.full-width-table .top-seller-table .ant-table-content .ant-table-cell:first-child {
  padding-left: 25px;
}

.full-width-table .top-seller-table .ant-table-content .ant-table-cell:last-child {
  padding-right: 25px;
}

.full-width-table .top-seller-table th {
  text-align: right;
  color: #272b41;
  font-weight: 500;
}

.full-width-table .top-seller-table td {
  color: #5a5f7d;
}

.full-width-table .top-seller-table .ant-table-tbody>tr>td {
  text-align: right;
}

.full-width-table .top-seller-table th:first-child,
.full-width-table .top-seller-table td:first-child {
  text-align: left !important;
}

/* Table Responsive */

.table-responsive .ant-table-content {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.table-responsive .ant-table-content .ant-table-cell {
  white-space: nowrap;
}

/* Rich TextEditor  */

.RichTextEditor__root___2QXK- {
  border: 0 none !important;
}

.RichTextEditor__root___2QXK- .EditorToolbar__root___3_Aqz {
  margin: 0;
  border-color: #f1f2f6;
}

.RichTextEditor__root___2QXK- .ButtonWrap__root___1EO_R button {
  padding: 0;
  border: 0 none;
  background: #fff;
  margin-right: 8px;
}

.RichTextEditor__root___2QXK- .Dropdown__root___3ALmx .Dropdown__value___34Py9 {
  border: 0 none;
}

.RichTextEditor__root___2QXK- .Dropdown__root___3ALmx select {
  border-right-width: 0px;
}

.RichTextEditor__editor___1QqIU .DraftEditor-editorContainer {
  border: 0 none;
}

/* ChatBox Dropdwon */

.atbd-chatbox__messageControl {
  min-width: 210px;
}

.atbd-chatbox__messageControl ul li a {
  padding: 4px 24px;
}

.atbd-chatbox__emoji {
  margin: -4.48px 0;
  padding: 0 10px;
}

.atbd-chatbox__emoji ul {
  display: flex;
  align-items: center;
}

.atbd-chatbox__emoji ul li {
  display: inline-block;
}

.atbd-chatbox__emoji ul li a {
  display: block;
  font-size: 20px;
  padding: 4px 7px;
  background: #fff;
}

.atbd-chatbox__emoji ul li a:hover {
  background-color: transparent;
}

.atbd-chatbox__emoji ul li a svg {
  margin: 0;
}

.rdrMonths {
  flex-wrap: wrap;
}

.ant-space {
  flex-wrap: wrap;
}

.ant-menu .ant-menu-submenu .ant-menu-submenu-title {
  display: flex;
  align-items: center;
}

.ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu-title,
.ant-menu-item {
  display: inline-flex;
}

.ant-menu-submenu-popup {
  z-index: 105;
}

.ant-menu-submenu-popup .ant-menu-sub {
  padding: 8px 0;
}

.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu-title,
.ant-menu-submenu-popup .ant-menu-item {
  display: block;
}

.ant-menu-submenu-popup .ant-menu-item {
  margin-bottom: 0 !important;
}

.ant-menu-sub.ant-menu-vertical {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.ant-menu-sub.ant-menu-vertical .ant-menu-item a {
  color: #5a5f7d;
}

/* page heading */

.ant-page-header {
  padding: 26px 30px 26px 30px !important;
}

.ant-page-header.header-boxed {
  padding: 26px 180px 26px 180px !important;
}

.ant-page-header-heading {
  align-items: center;
}

.ant-page-header-heading-title {
  text-transform: capitalize;
}

/* Antd drag & drop */

.row-dragging {
  /* background: #fafafa; */
  /* border: 1px solid #ccc; */
  box-shadow: 0 15px 50px #9299b820;
  display: flex;
}

.row-dragging tr {
  box-shadow: 0 15px 50px #9299b820;
}

.row-dragging td {
  padding: 16px;
  color: #000;
  position: relative;
  z-index: 9999;
  opacity: 0.5;
  vertical-align: middle;
}

.row-dragging td .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #20c997;
  border-color: #20c997;
}

.row-dragging td .todos-action,
.row-dragging td .table-actions {
  display: none;
}

.row-dragging td .user-info figcaption {
  display: none;
}

.row-dragging td .feather-move,
.row-dragging td .drag_email,
.row-dragging td .drag_company,
.row-dragging td .drag_designation,
.row-dragging td .drag_join-date,
.row-dragging td .active {
  display: inline-block;
  margin-top: 10px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

/* File Export Import Modal */
.sDash_export-wrap .ant-modal {
  width: 390px !important;
}

.sDash_export-wrap .ant-modal .ant-modal-header {
  border-bottom: 0 none;
  padding: 18px 30px 0 30px
}

.sDash_export-wrap .ant-modal .ant-modal-body {
  padding: 25px 30px 30px 30px;
}

.sDash_export-wrap .ant-modal .sDash-button-grp {
  text-align: right;
}

.sDash_export-wrap .ant-modal .sDash-button-grp {
  margin: 20px -8px -8px -8px;
}

.sDash_export-wrap .ant-modal .sDash-button-grp button {
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  height: 40px;
  padding: 0 16px;
  margin: 8px;
}

.sDash_export-wrap .ant-modal .sDash-button-grp button.ant-btn-white {
  color: #9299B8 !important;
}

.sDash_export-wrap .ant-form-item-control-input {
  min-height: 36px;
}

.sDash_export-wrap .ant-form-item-control-input input {
  font-size: 14px;
  font-weight: 400;
  padding: 6px 20px;
  border-radius: 5px;
  color: #9299B8;
}

.sDash_export-wrap .ant-select-single {
  width: 100% !important;
}

.sDash_export-wrap .ant-form .ant-form-item {
  margin-bottom: 15px;
}

.sDash_export-wrap .ant-select-single .ant-select-selector {
  padding: 0 20px;
  border-color: #E3E6EF !important;
}

.sDash_create-file .sDash-button-grp {
  text-align: right;
}

.sDash_create-file .ant-modal {
  width: 390px !important;
}

.sDash_create-file .ant-modal .ant-form-item {
  margin-bottom: 20px;
}

.sDash_create-file .ant-modal-header {
  border-bottom: 0 none;
  padding-bottom: 6px;
}

.sDash_create-file .sDash-button-grp button {
  height: 40px;
  border-radius: 5px;
  margin: 5px;
}

.sDash_create-file .ant-form-item-control-input {
  border-radius: 5px;
}

.sDash_create-file .ant-form-item-control-input input {
  border: 1px solid #E3E6EF;
}

/* Task Modal */
.sDash_task-details .ant-modal-content .ant-modal-close {
  top: 10px;
}

.sDash_task-details .ant-modal-header {
  border-bottom: 0 none;
  padding: 30px 30px 0;
}

.sDash_task-details .ant-modal {
  width: 600px !important;
}

.sDash_task-details .ant-modal-header .ant-modal-title h4 {
  font-size: 20px;
  font-weight: 500;
  color: #272B41;
  margin-bottom: 4px;
}

.sDash_task-details .ant-modal-header .ant-modal-title .sub-text {
  font-size: 14px;
  font-weight: 400;
  color: #868EAE;
}

.sDash_task-details .sDash_task-details-modal .sDash_task-details__label {
  font-size: 16px;
  display: block;
  margin-bottom: 8px;
}

.sDash_task-details .sDash_task-details-modal .sDash_task-details-modal__description textarea {
  padding: 10px 20px;
  min-height: 88px;
  width: 100%;
  border: 0 none;
  border-radius: 4px;
  background-color: #F4F5F7;
  resize: none;
}

.sDash_task-details .sDash_task-details-modal .sDash_task-details-modal__description textarea:focus {
  outline: none;
}

.sDash_task-details .sDash_task-details-modal .sDash_task-details-modal__description textarea::placeholder {
  color: #5A5F7D;
  font-size: 15px;
}

.sDash_task-details .ant-modal-body {
  padding: 14px 30px 30px 30px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block {
  margin-top: 26px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-wrap {
  position: relative;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form {
  position: absolute;
  width: 240px;
  padding: 18px;
  left: 0;
  top: 50px;
  box-shadow: 0 15px 30px #9299BB30;
  background-color: #fff;
  border: 1px solid #E3E6EF;
  border-radius: 6px;
  z-index: 222;
}

html[dir='rtl'] .sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form {
  left: auto;
  right: 0;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form .add-checklist {
  padding: 10px;
  border-radius: 4px;
  background-color: #fff;
  width: 100%;
  height: 38px;
  border: 1px solid #E3E6EF;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form .addChecklist-form-action {
  margin-top: 15px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form .addChecklist-form-action a {
  position: relative;
  top: 3px;
  display: inline-flex;
  align-items: center;
  line-height: 1;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form .addChecklist-form-action a svg {
  color: #9299BB;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form .addChecklist-form-action .btn-add {
  margin-right: 15px;
}

html[dir='rtl'] .sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form .addChecklist-form-action .btn-add {
  margin-left: 15px;
  margin-right: 0;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block button {
  height: 38px;
  padding: 0px 18.37px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row {
  margin-top: 26px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist-item:not(:last-child) {
  margin-bottom: 30px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist-item__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist-item__top button {
  padding: 0px 16.32px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist-item__title {
  font-size: 16px;
  font-weight: 500;
  color: #272B41;
  margin-bottom: 0;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist__progress .ant-progress-inner .ant-progress-bg {
  height: 5px !important;
  background-color: #20C997;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist__progress .ant-progress .ant-progress-text {
  font-size: 12px;
  font-weight: 500;
  order: -1;
  margin: 0 10px 0 0;
}

html[dir='rtl'] .sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist__progress .ant-progress .ant-progress-text {
  margin: 0 0 0 10px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-tasks {
  margin: 5px 0 5px 0;
}

.sDash_task-details .ant-modal-body .sDash_checklist-tasks .ant-checkbox-wrapper .ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border-color: #C6D0DC;
}

.sDash_task-details .ant-modal-body .sDash_checklist-tasks .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #20C997;
}

.sDash_task-details .ant-modal-body .sDash_checklist-tasks .ant-checkbox-wrapper .ant-checkbox-checked:after {
  border-color: #20C997;
}

.sDash_task-details .ant-modal-body .sDash_checklist-item .sDash_checklist-tasks-wrap button {
  padding: 0px 35px;
}

.sDash_task-details .ant-modal-body {
  max-height: 800px;
  overflow-y: auto;
}

@media (max-width: 767px) {
  .ant-page-header {
    padding: 26px 15px 26px 15px !important;
  }
}

.page-header-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: -6px -4px;
}

.page-header-actions button {
  font-size: 12px;
  font-weight: 500;
  height: 34px;
  padding: 0 12.32px;
  box-shadow: 0 3px 5px #9299b805;
  margin: 6px 4px;
}

.page-header-actions button.ant-btn-white:focus {
  background-color: #fff !important;
}

.page-header-actions button+button {
  margin-left: 8px;
}

.page-header-actions button.ant-btn-white svg {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  color: #008455;
}

/* Layout Css */

.ant-menu-dark .ant-menu-inline.ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #272b41 !important;
}

.ant-menu-dark .ant-menu-item a {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.65) !important;
}

.ant-menu-dark .ant-menu-item a:hover {
  color: #ffffff;
}

.ant-menu-dark .ant-menu-submenu span {
  color: rgba(255, 255, 255, 0.65) !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #82a600 !important;
}

.ant-menu-inline-collapsed-tooltip a {
  color: #000 !important;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: transparent;
}

/* Chart Label */

.chart-label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
  color: #5a5f7d;
}

.chart-label .label-dot {
  margin-right: 8px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.chart-label .label-dot.dot-success {
  background: #20c997;
}

.chart-label .label-dot.dot-info {
  background: #008455;
}

.chart-label .label-dot.dot-warning {
  background: #fa8b0c;
}

/* NOtification CSS */

.ant-notification {
  z-index: 99999 !important;
}

.rdrInputRange {
  padding-left: 10px !important;
}

/* Overlay Dark */
.overlay-dark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #10122130;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}

.overlay-dark.show {
  z-index: 999;
  opacity: 1;
  visibility: visible;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

/* Responsive CSS */

@media only screen and (max-width: 1599px) {
  .ant-page-header.header-boxed {
    padding: 26px 130px 26px 130px !important;
  }
}

@media only screen and (max-width: 1500px) {
  .full-width-table .revenue-table {
    min-height: 100%;
  }

  .pop-confirm .ant-btn {
    padding: 0 20px;
    max-width: 60px;
  }

  .pop-confirm.pop-confirm-right {
    margin-left: 300px !important;
  }

  .pop-confirm.pop-confirm-bottom,
  .pop-confirm.pop-confirm-top {
    margin-left: 80px !important;
  }
}

@media only screen and (max-width: 1399px) {
  .ant-page-header.header-boxed {
    padding: 26px 50px 26px 50px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .ant-page-header {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 991px) {
  .ant-page-header.header-boxed {
    padding: 26px 30px 26px 30px !important;
  }

  .rdrMonths .rdrMonth {
    width: 100%;
    margin-bottom: 30px;
  }

  .rdrDateRangePickerWrapper.PreviewArea {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .rdrCalendarWrapper.rdrDateRangeWrapper {
    margin-top: 20px;
  }

  .ant-menu-inline-collapsed-tooltip {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .ant-page-header-heading-left {
    max-width: 320px;
  }
}

@media only screen and (max-width: 767px) {
  .ant-page-header {
    padding: 26px 15px 26px 15px !important;
  }

  .ant-page-header-heading {
    flex-flow: column;
    align-items: center;
    justify-content: center !important;
  }

  .ant-page-header-heading-left {
    max-width: 100%;
  }

  .ant-page-header-heading .ant-page-header-heading-title {
    margin-right: 0;
    white-space: normal;
    text-align: center;
  }

  .ant-page-header-heading-extra {
    white-space: normal !important;
    margin: 12px 0 4px !important;
  }

  .ant-page-header-heading-extra .page-header-actions {
    white-space: normal !important;
    text-align: center;
  }

  .ant-card-body {
    padding: 20px !important;
  }

  .ant-card-head {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .ant-pagination .ant-pagination-item,
  .ant-pagination .ant-pagination-item.ant-pagination-prev,
  .ant-pagination .ant-pagination-item.ant-pagination-next,
  .ant-pagination .ant-pagination-jump-prev {
    margin-right: 5px;
  }

  .ant-pagination li.ant-pagination-item,
  .ant-pagination li.ant-pagination-prev,
  .ant-pagination li.ant-pagination-next {
    height: 25px;
    min-width: 25px;
    line-height: 22px;
  }

  .ant-pagination li.ant-pagination-prev .anticon,
  .ant-pagination li.ant-pagination-next .anticon {
    vertical-align: 0.15em;
  }

  .ant-table-pagination {
    float: none !important;
    text-align: center;
  }

  .ant-table-pagination li.ant-pagination-total-text {
    display: block;
    margin-bottom: 8px;
  }

  .ant-table-pagination li {
    margin-right: 8px !important;
  }

  .ant-pagination .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 25px !important;
  }

  .ant-pagination .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 23px !important;
  }

  .rdrDefinedRangesWrapper {
    border-right: 0 none;
  }
}

@media only screen and (max-width: 575px) {

  .btn-inc,
  .btn-dec {
    height: 30px;
    width: 30px;
  }

  .ant-page-header {
    padding: 16px 20px !important;
  }

  .ant-layout-header {
    height: auto;
  }

  .ant-card-head {
    line-height: 1;
  }

  .ant-card-head-title {
    white-space: normal !important;
  }

  .pop-confirm .ant-btn {
    max-width: 60px;
    padding: 0 20px;
  }

  .pop-confirm.pop-confirm-right {
    margin-left: 320px !important;
  }

  .pop-confirm.pop-confirm-bottom {
    margin-left: 95px !important;
  }

  /* Card Heading */
  .ant-card-head-wrapper {
    flex-flow: column;
    align-items: center;
  }

  .ant-card-head-wrapper .ant-card-extra {
    padding: 10px 0 16px !important;
    float: none;
    margin: 0;
    flex-flow: column;
  }

  .ant-card-head-wrapper .ant-card-extra .ant-dropdown-trigger+.card-nav {
    margin-bottom: 8px;
  }

  .ant-card-head-wrapper .ant-card-extra .ant-dropdown-trigger {
    margin: 0 !important;
  }

  .ant-card-head-wrapper .ant-card-head-title {
    padding: 20px 0 0px !important;
  }

  .card-nav ul li.active:before {
    display: none;
  }

  .ant-card-head-title>div {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .ant-card-head-title>div span {
    margin: 8px 0 0;
  }
}

.bmzxig .ant-table tr th:first-child,
.bmzxig .ant-table tr td:first-child {
  padding-right: 15px;
}

/* Emprt */

.ant-empty .ant-empty-footer button {
  padding: 0px 15.58px !important;
}

/* Add Event Modal */
.addEvent-modal .ant-modal-header {
  padding: 20px 25px;
}

.addEvent-modal .ant-modal-header .ant-modal-close-x svg {
  color: #5a5f7d;
}

.addEvent-modal .ant-modal-header .ant-modal-title {
  font-size: 15px;
  font-weight: 500;
  color: #272b41;
}

.addEvent-modal .ant-modal-body {
  padding: 23px 25px;
}

/* Event Dropdown */

.event-dropdown {
  min-width: auto !important;
  max-width: 450px;
  padding: 0 !important;
  margin: 6px 0 0 !important;
  box-shadow: 0 10px 40px #9299b820 !important;
}

.event-dropdown div {
  border-radius: 8px;
}

.event-dropdown .ant-card {
  width: 100% !important;
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 479px) {
  .placement-confirm {
    display: flex;
    flex-wrap: wrap;
    margin: -5px -10px 0 -5px;
  }

  .placement-confirm .pop-confirm {
    flex: 0 0 50%;
  }

  .pop-confirm .ant-btn {
    display: block;
    padding: 0 20px;
    max-width: 80px;
    min-width: 80px;
    margin: 10px;
  }

  .pop-confirm.pop-confirm-right {
    margin-left: 0px !important;
  }

  .pop-confirm.pop-confirm-top {
    margin-left: 0px !important;
  }

  .pop-confirm.pop-confirm-bottom {
    margin-left: 0px !important;
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 400px) {
  .ant-select {
    width: 100% !important;
  }

  .rdrDefinedRangesWrapper {
    width: 100% !important;
  }

  .rdrDateRangePickerWrapper {
    flex-wrap: wrap;
  }

  .atbd-top-dropdwon {
    width: 280px;
    min-width: 180px;
  }

  .atbd-top-dropdwon .atbd-top-dropdwon__title {
    min-height: 40px;
  }

  .atbd-top-dropdwon .atbd-top-dropdwon__nav li:not(:last-child) {
    margin-bottom: 10px;
  }

  .atbd-top-dropdwon .atbd-top-dropdwon__nav li a {
    padding: 10px 0px;
  }

  .atbd-top-dropdwon .atbd-top-dropdwon__content img {
    margin-right: 15px;
  }

  .atbd-top-dropdwon .atbd-top-dropdwon__content figcaption {
    margin-left: 0;
  }

  .atbd-top-dropdwon .atbd-top-dropdwon__content figcaption .atbd-top-dropdwonText {
    min-width: 155px;
  }

  .ant-drawer-content-wrapper {
    width: 260px !important;
  }

  .rdrCalendarWrapper.rdrDateRangeWrapper {
    margin-top: 0;
  }
}

@media only screen and (max-width: 379px) {
  .ant-card-head-wrapper .ant-card-extra .ant-radio-button-wrapper {
    height: 32px !important;
    line-height: 30px !important;
  }

  .ant-notification-notice {
    width: 275px;
  }
}

@media (max-width: 991px) {
  .mail-sideabr {
    box-shadow: 0 0 10px #00000020;
  }
}

/* Invoice Print Style */
@media print {
  .invoice-area .ant-page-header {
    display: none;
  }

  .customizer-trigger {
    display: none;
  }
}

/* .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #fff !important;
} */

/* custom label */

/* card spin loader */

.ant-card-body .sd-spin div,
.ant-card-body .spin div {
  position: relative;
}

.ant-card-body .sd-spin,
.ant-card-body .spin {
  height: 200px;
}

.ant-card-body {
  position: relative;
}

.ant-card-body .sd-spin .ant-spin,
.ant-card-body .spin .ant-spin {
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 200px;
}

.starActive svg {
  color: red;
}

/* vectormap zoom in / zoom out */

.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 27px;
  height: 27px;
  background: none;
  color: #5a5f7d;
  border: 1px solid #f1f2f6;
  padding: 0;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: auto;
  right: 0;
  top: auto;
  background-color: #fff;
}

.jvectormap-zoomin {
  border-radius: 6px 6px 0 0;
  bottom: 36px;
}

.jvectormap-zoomout {
  border-radius: 0 0 6px 6px;
  bottom: 10px;
}

.jvectormap-tip {
  padding: 7px 12px;
  border: 0 none;
  font-size: 12px;
  background: #272b41;
}

.btn-rtl {
  width: 60px;
  height: 60px;
  border: 1px solid #fff;
  background: #4347d9;
  color: #fff;
  position: fixed;
  right: 0;
  top: 50%;
  margin-top: -30px;
  z-index: 99999999;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* masonry-grid start from here */

.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -10px;
  /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 10px;
  /* gutter size */
  background-clip: padding-box;
}

/* Style your items */

.my-masonry-grid_column>div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

.common-ul {
  padding: 5px 0;
  padding-left: 10px;
}

.display {
  display: none;
}

li.active>.display {
  display: block;
}


/* rtl section from here */
html[dir='rtl'] .ant-card-head-title span {
  margin-right: 15px;
}

html[dir='rtl'] span[aria-label="arrow-right"] svg,
html[dir='rtl'] span[aria-label="arrow-left"] svg {
  transform: rotateY(180deg);
}

html[dir='rtl'] .ant-menu.ant-menu-vertical {
  text-align: right !important;
}

html[dir='rtl'] .ant-menu.ant-menu-vertical .ant-menu-submenu-placement-rightTop {
  left: -170px !important;
}

html[dir='rtl'] .ant-menu.ant-menu-vertical .ant-menu-vertical-left {
  text-align: right;
}

html[dir='rtl'] .ant-menu.ant-menu-vertical .ant-menu-submenu-arrow {
  right: auto;
  left: 16px;
  transform: rotateY(180deg);
}

html[dir='rtl'] .ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 15px !important;
}

/* Auth0 style */
.auth0-lock.auth0-lock .auth0-lock-header-logo {
  width: 180px !important;
  margin: 0 !important;
  display: none !important;
}

.auth0-lock.auth0-lock .auth0-lock-name {
  font-size: 18px !important;
}

.auth0-lock.auth0-lock .auth0-lock-close-button,
.auth0-lock.auth0-lock .auth0-lock-back-button {
  box-shadow: none !important;
  padding: 2px !important;
}

.common-ul {
  padding: 5px 0;
  padding-left: 10px;
}

.display {
  display: none;
}

li.active>.display {
  display: block;
}


.main {
  flex-direction: column;
  justify-content: space-between;
  padding: 0rem;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  background: linear-gradient(180deg, #335924 0%, #0A2D47 100%);
}

.background_gradient {
  min-height: 530px;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(180deg, #bbe0ad38 15%, #14441b 100%) */
}

.player_background_gradient {
  min-height: 760px;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #bbe0ad38 15%, #14441b 100%)
}

/* background: radial-gradient(circle at top right, transparent 60%, rgba(0, 0, 0, 0.3) 100%, rgba(0, 0, 0, 0.6) 80%, #335924 79%),
  linear-gradient(to top, #335924 20%, rgba(0, 0, 0, 0.6) 55%, rgba(0, 0, 0, 0.3) 90%); */
.homepage_section {
  width: 100%;
  overflow-x: hidden;
  height: 100%;
  min-height: 530px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../img/Background1.png);
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  /* background:  linear-gradient(180deg, #335924 0%, #0A2D47 100%) */
}

.support_athlete_section {
  width: 100%;
  height: 100%;
  min-height: 580px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../img/SupporterLanding.jpg);
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.player_section {
  width: 100%;
  height: 100%;
  min-height: 760px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../img/UseOnPlayerPage.JPG);
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.paragraph_text {
  font-size: 23px;
  font-family: 'Urbanist', sans-serif;
  font-weight: 400;
  word-wrap: break-word;
  align-self: stretch;
}

.page_section {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex
}

.crowd {
  color: white;
  font-size: 150px;
  font-family: 'Urbanist', sans-serif;
  font-weight: 800;
  word-wrap: break-word;
  opacity: 0;
  transform: translateX(60%);
  animation: slideIn 2s ease forwards;
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.serve {
  color: white;
  font-size: 150px;
  font-family: 'Urbanist', sans-serif;
  font-weight: 800;
  word-wrap: break-word;
  text-align: end;
  opacity: 0;
  transform: translateX(-100%);
  animation: slideIn 2s ease forwards;
}


.description {
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  font-size: 0.85rem;
  max-width: var(--max-width);
  width: 100%;
  z-index: 2;
  font-family: var(--font-mono);
}

.description a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.description p {
  position: relative;
  margin: 0;
  padding: 1rem;
  background-color: rgba(var(--callout-rgb), 0.5);
  border: 1px solid rgba(var(--callout-border-rgb), 0.3);
  border-radius: var(--border-radius);
}

.code {
  font-weight: 700;
  font-family: var(--font-mono);
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(25%, auto));
  max-width: 100%;
  width: var(--max-width);
}

.card {
  padding: 1rem 1.2rem;
  border-radius: var(--border-radius);
  background: rgba(var(--card-rgb), 0);
  border: 1px solid rgba(var(--card-border-rgb), 0);
  transition: background 200ms, border 200ms;
}

.card span {
  display: inline-block;
  transition: transform 200ms;
}

.card h2 {
  font-weight: 600;
  margin-bottom: 0.7rem;
}

.card p {
  margin: 0;
  opacity: 0.6;
  font-size: 0.9rem;
  line-height: 1.5;
  max-width: 30ch;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 4rem 0;
}

.center::before {
  background: var(--secondary-glow);
  border-radius: 50%;
  width: 480px;
  height: 360px;
  margin-left: -400px;
}

.center::after {
  background: var(--primary-glow);
  width: 240px;
  height: 180px;
  z-index: -1;
}

.center::before,
.center::after {
  content: '';
  left: 50%;
  position: absolute;
  filter: blur(45px);
  transform: translateZ(0);
}

.logo {
  position: relative;
}

/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
  .card:hover {
    background: rgba(var(--card-rgb), 0.1);
    border: 1px solid rgba(var(--card-border-rgb), 0.15);
  }

  .card:hover span {
    transform: translateX(4px);
  }
}

@media (prefers-reduced-motion) {
  .card:hover span {
    transform: none;
  }
}

/* Mobile */
@media (max-width: 700px) {
  .content {
    padding: 4rem;
  }

  .adjust-hr-line {
    display: flex !important;
    justify-content: center;
  }

  .crowd {
    font-size: 80px;
  }

  .middle_block {
    margin: 5px 5px !important;
    /* height: 480px !important; */
  }

  .serve {
    font-size: 80px;
    top: -155px;
    position: absolute;
  }

  .middle_block {
    padding: 0px !important;
    border: none !important;
  }

  .adjust-mobile-padding {
    padding: 10px 0px !important;
  }

  .mobile_join_comminity {
    top: 120px;
    position: relative;
  }

  .adjust_padding {
    padding: 10px !important;
  }

  .grid {
    grid-template-columns: 1fr;
    margin-bottom: 120px;
    max-width: 320px;
    text-align: center;
  }

  .card {
    padding: 1rem 2.5rem;
  }

  .card h2 {
    margin-bottom: 0.5rem;
  }

  .center {
    padding: 8rem 0 6rem;
  }

  .center::before {
    transform: none;
    height: 300px;
  }

  .description {
    font-size: 0.8rem;
  }

  .description a {
    padding: 1rem;
  }

  .description p,
  .description div {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
  }

  .description p {
    align-items: center;
    inset: 0 0 auto;
    padding: 2rem 1rem 1.4rem;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(var(--callout-border-rgb), 0.25);
    background: linear-gradient(to bottom,
        rgba(var(--background-start-rgb), 1),
        rgba(var(--callout-rgb), 0.5));
    background-clip: padding-box;
    backdrop-filter: blur(24px);
  }

  .description div {
    align-items: flex-end;
    pointer-events: none;
    inset: auto 0 0;
    padding: 2rem;
    height: 200px;
    background: linear-gradient(to bottom,
        transparent 0%,
        rgb(var(--background-end-rgb)) 40%);
    z-index: 1;
  }

  .title {
    font-size: 19px !important;
    line-height: 40px !important;
  }

  .title-host {
    font-size: 30px !important;
  }

  .title-host span {
    font-size: 27px !important;
  }

  .paragraph_text {
    font-size: 20px !important;
  }

  .subtitle {
    font-size: 32px !important;
  }

  .inner_white_block {
    padding: 15px !important;
  }

  .change-block-order:nth-child(1) {
    order: 2 !important;
    padding: 5px 10px !important;
  }

  .change-block-order:nth-child(2) {
    order: 1 !important;
  }

  .subpage-header-title {
    font-size: 40px !important;
    line-height: 50px !important;
    margin-top: 35px !important;
  }

  .join_community_text {
    font-size: 17px !important;
  }

  .image_wrapper {
    margin-top: 50px !important;
  }

  .image_wrapper img {
    height: 260px !important;
  }

}

.title-host {
  font-size: 40px;
  line-height: 40px !important;
  align-self: stretch;
  color: #335924;
  font-family: 'Urbanist', sans-serif;
  font-weight: 600;
  word-wrap: break-word
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
  .grid {
    grid-template-columns: repeat(2, 50%);
  }

  .crowd {
    font-size: 110px;
  }

  .serve {
    font-size: 110px;
    top: 140px;
  }

  .mobile_join_comminity {
    top: 80px;
    position: relative;
  }
}

@media (prefers-color-scheme: dark) {
  .vercelLogo {
    filter: invert(1);
  }

  .logo {
    filter: invert(1) drop-shadow(0 0 0.3rem #ffffff70);
  }
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}


.image-text-overlay {
  width: 100%;
  height: 225px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  color: white;
}

.overlay-title {
  font-size: 27px;
  text-transform: uppercase;
}

.overlay-subtext {
  font-size: 20px;
}

.join_community_text {
  color: white;
  font-size: 21px;
  font-family: 'Urbanist', sans-serif;
  font-weight: 400;
  word-wrap: break-word
}




.community_button {
  width: 230px;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #D2F64F;
  border-radius: 360px;
  overflow: 'hidden';
  justify-content: center;
  align-items: center;
  gap: 4;
  display: flex
}

.middle_block {
  flex: 1 1 0 !important;
  /* margin: 20px;
  height: 257px;
  padding-top: 20px !important;
  padding-bottom: 15px !important; */
  padding-left: 20px;
  padding-right: 0px;
  border: 1px #1082C5 solid;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24;
  display: inline-flex
}

@media (min-width: 767px) {

  .middle_block:hover {
    border: 1px #b2ee39 solid;
    border-radius: 25px;
    transition: transform 0.3s ease-in-out;
    transform: scale(1.1);
    /* background: linear-gradient(180deg, #335924 0%, #0A2D47 100%); */

  }

  .middle_block:hover .image_hover {
    border-radius: 25px;
    /* top: -20px; */
    /* height: 257px; */
    position: relative;
  }

  .event_bg:hover {
    transition: transform 0.3s ease-in-out;
    transform: scale(1.1);
  }

}


.event_bg:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease-in-out;
  z-index: -1;
  /* Ensure it's behind the content */
}

.event_bg:hover:before {
  transform: scale(1.1);
  /* Adjust the scale factor as needed */
}

.title {
  align-self: stretch;
  color: #335924;
  font-size: 23px;
  font-family: 'Urbanist', sans-serif;
  font-weight: 600;
  word-wrap: break-word
}

.title_description {
  align-self: stretch;
  color: #335924;
  font-size: 16px;
  font-family: 'Urbanist', sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
  word-wrap: break-word
}

.event_bg {
  width: 296px;
  height: 480px;
  padding: 15px;
  background: #0D3D60;
  border-radius: 24px;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24;
  display: inline-flex;
  overflow: hidden;
}

.event_no {
  opacity: 0.64;
  text-align: right;
  color: white;
  font-size: 32px;
  font-family: Satoshi Variable;
  font-weight: 300;
  padding-left: 225px;
  word-wrap: break-word
}

.event_name_block {
  align-self: stretch;
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  display: flex
}

.event_date {
  color: #DDF87B;
  font-size: 11px;
  font-family: 'Urbanist', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.76;
  word-wrap: break-word
}

.event_name {
  align-self: stretch;
  color: white;
  font-size: 24px;
  font-family: 'Urbanist', sans-serif;
  font-weight: 600;
  word-wrap: break-word
}


.last-event-played-player {
  display: block;
  float: left;
  text-transform: capitalize;
  width: 100%;
  padding: 10px;
}

.last-events-played .last-event-played-inner {
  position: relative;
}

.left-player .last-event-played-info {
  float: left;
  padding-right: 20px;
}

.left-player .last-event-played-info {
  text-align: left;
}

.last-event-played-info {
  float: left;
  width: calc(100% - 140px);
}

.left-player .last-events-played-rank {
  margin-left: 8px;
}

.left-player .last-events-played-rank {
  float: left;
}

.last-events-played-rank {
  color: #051224;
  width: auto;
  float: left;
  font: 15px "roboto_regular";
  line-height: 1em;
  letter-spacing: 0px;
  height: 30px;
  margin-top: 20px;
  padding-left: 18px;
  position: relative;
}

.last-events-played-rank .player-rank-label {
  bottom: 0;
  position: absolute;
  text-transform: capitalize;
  left: 18px;
}

.player-rank-label {
  transform: rotate(-90deg);
  transform-origin: bottom left;
}

.last-events-played-rank .player-rank-value {
  font: 35px "roboto_bold";
  line-height: 32px;
  letter-spacing: 0px;
}

.left-player .last-events-played-flag {
  float: left;
}

.last-events-played-flag {
  border: 1px solid #ddd;
  height: 31px;
  width: 44px;
  margin-top: 20px;
}

.last-events-played-flag img {
  height: 100%;
  width: 100%;
}

.left-player .last-events-played-image {
  float: right;
}

.last-events-played-image {
  height: 120px;
  width: 120px;
}

.last-events-played-image:hover {
  border: 1px solid #051224;
}

.button-border-row-holder {
  margin-left: 2px;
}

.button-border-row-holder {
  clear: both;
  margin-bottom: 0 !important;
  margin-top: 20px;
}

.last-event-played-player .last-name {
  font: 26px Arial;
  line-height: 1em;
  letter-spacing: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: none;
  color: #051224;
  font-weight: 700;
}

.last-event-played-player .first-name {
  font: 21px Arial;
  line-height: 1em;
  letter-spacing: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #051224;
  font-weight: 700;
}

.button-border-player {
  border: 1px solid #051224;
  background-color: transparent;
  color: #051224;
  line-height: 26px;
  text-align: center;
  position: relative;
  border-radius: 3px;
}


.jDsUdq {
  padding: 0px !important;
}

.section_wrapper {
  max-width: 1202px;
  margin: 0 auto;
  position: relative;
}

.section_wrapper_home {
  max-width: 1402px;
  margin: 0 auto;
  position: relative;
}

.mcb-wrap {
  float: left;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}

.homepage_title {
  font-size: 54px;
  line-height: 64px;
  font-weight: 600;
  letter-spacing: 0px;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
}



.big {
  font-size: 16px;
  line-height: 28px;
  font-weight: 300;
  letter-spacing: 0px;
}

hr.no_line {
  background: none;
  color: transparent;
}

.button_theme {
  background-color: #d84659;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  margin: 0 10px 15px 0;
  border-radius: 5px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .03), inset 0px 4px 3px -2px rgba(0, 0, 0, .07);
  transition: color .1s ease-in-out, background-color .1s ease-in-out, border-color .1s ease-in-out;
}

.one-fourth.column,
.four.columns {
  width: 23%;
}

.column,
.columns {
  float: left;
  margin: 0 1% 40px;
}

.column_attr.align_center {
  text-align: center;
}

.image_frame.no_border {
  border-width: 0;
}

.image_frame {
  border-color: #f8f8f8;
}

hr.no_line {
  background: none;
  border: none;
  color: transparent;
}

.option_label {
  color: #2a264a;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  letter-spacing: 0px;
  margin-bottom: 15px;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
}






.mcb_section .mcb-wrap .mcb-item-hn1magwqj .desc,
.mcb_section .mcb-wrap .mcb-item-hn1magwqj .desc a {
  color: #002A7D;
}

.mcb_section .mcb-wrap .mcb-item-hn1magwqj .desc {
  font-size: 10em;
  font-weight: 800;
  /* font-family: 'Plus Jakarta Sans'; */
  line-height: 1em;
}

.mcb-column-inner {
  position: relative;
  margin-top: var(--mfn-column-gap-top);
  margin-bottom: var(--mfn-column-gap-bottom);
  margin-left: var(--mfn-column-gap-left);
  margin-right: var(--mfn-column-gap-right);
}

.tennis-homepage-title {
  font-size: 69px;
  line-height: 76px;
  font-weight: 800;
  color: #002A7D;
  letter-spacing: -2px;
}

.tennis-homepage-title-three {
  color: #002a7d;
  font-size: 23px;
  line-height: 28px;
  font-weight: 800;
  letter-spacing: 0px;
}

.section,
.mcb-wrap,
.mcb-column {
  background-position: left top;
  background-repeat: no-repeat;
}

.button-custom {
  color: #000000;
  background-color: #d7fc69;
  border-color: transparent;
  font-family: Plus Jakarta Sans;
  font-size: 15px;
  line-height: 15px;
  font-weight: 700;
  letter-spacing: 0px;
  padding: 10px 20px 10px 20px;
  border-width: 0px;
  border-radius: 60px;
}

.mcb_section .mcb-wrap .mcb-item-l5m55lymo .mfn-list .mfn-list-item {
  padding-bottom: 60px;
}

.mcb_section .mcb-wrap .mcb-item-l5m55lymo .mfn-list {
  --mfn-list-icon-color: #FFFFFF;
  --mfn-list-icon-spacing: 30px;
}

.mfn-list-ordered {
  counter-reset: my-sec-counter;
}

.mfn-list {
  margin: 0;
  padding: 0;
}

.mfn-list {
  --mfn-list-icon-spacing: 10px;
  --mfn-list-icon-size: 20px;
  --mfn-list-icon-color: #161922;
  --mfn-list-divider-width: 100%;
  --mfn-list-divider-height: 1px;
  --mfn-list-divider-color: rgba(0, 0, 0, .1);
}

.mfn-list-top .mfn-list-item {
  align-items: flex-start;
}

.mfn-list-left .mfn-list-item {
  justify-content: flex-start;
}

.mfn-list .mfn-list-item {
  display: flex;
  position: relative;
  padding: 7px 0;
}

.mfn-list .mfn-list-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  line-height: 0;
  margin-right: var(--mfn-list-icon-spacing);
  position: relative;
}

.mcb_section .mcb-wrap .mcb-item-l5m55lymo .mfn-list-desc {
  color: #4B5E75;
}

.list-title {
  color: #002a7d;
  font-size: 23px;
  line-height: 28px;
  font-weight: 800;
  letter-spacing: 0px;
  font-family: "Plus Jakarta Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  margin-bottom: 15px;
}

.mfn-list-desc {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.upcoming-event {
  font-size: 48px;
  line-height: 53px;
  font-weight: 800;
  letter-spacing: -2px;
  color: #002a7d;
}

.mcb_section .mcb-wrap .mcb-item-ffsn1stg .image_frame {
  border-radius: 16px 16px 16px 16px;
}


.image_frame.no_border {
  border-width: 0;
}

.image_frame {
  border-color: #f8f8f8;
}

.image_frame .image_wrapper,
.wp-caption img,
.icon_box .icon_wrapper:before {
  border-color: #e2e2e2;
}

.image_frame .image_wrapper {
  position: relative;
  overflow: hidden;
}

.image_frame_details {
  margin: 5px;
}

.image_frame .image_wrapper,
.wp-caption img {
  box-sizing: border-box;
}

.one.column {
  width: 100%;
}

.column_column {
  display: flex;
}

.mcb-column {
  position: relative;
}

.section,
.mcb-wrap,
.mcb-column {
  background-position: left top;
  background-repeat: no-repeat;
}

.mfn-looped-items-slider .mfn-queryloop-item-wrapper .mcb-column:last-child .mcb-column-inner {
  margin-bottom: 0;
}

.mcb_section .mcb-wrap .mcb-item-74pcr0e53 .mcb-column-inner-74pcr0e53 {
  padding-top: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  margin-right: 40px;
  margin-left: 40px;
  margin-top: -60px;
  background-color: #FFFFFF;
  border-radius: 16px 16px 16px 16px;
}

.mcb-item-column-inner {
  width: 100%;
  word-break: break-word;
}

.image-slide-title {
  color: #002a7d;
  font-size: 15px;
  line-height: 26px;
  font-weight: 700;
  letter-spacing: 0px;
  font-family: "Plus Jakarta Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.welcome_to {
  z-index: 9;
  margin-bottom: 0px;
  /* font-family: "Plus Jakarta Sans"; */
  height: 35px;
  width: 100%;
  color: rgb(255, 255, 255);
  text-decoration: none;
  white-space: nowrap;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 40px;
  letter-spacing: 0px;
  font-weight: 800;
  font-size: 33px;
  backdrop-filter: none;
  filter: none;
  transform-origin: 50% 50%;
  opacity: 1;
  transform: translate(0px, 0px);
  visibility: visible;
}

.sky_title {
  z-index: 8;
  margin-bottom: 0px;
  /* font-family: "Plus Jakarta Sans"; */
  height: auto;
  width: auto;
  color: rgb(255, 255, 255);
  text-decoration: none;
  white-space: nowrap;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 190px;
  letter-spacing: 0px;
  font-weight: 800;
  font-size: 130px;
  backdrop-filter: none;
  filter: none;
  transform-origin: 50% 50%;
  opacity: 1;
  transform: translate(0px, 0px);
  visibility: visible;
}

.demo_title {
  color: #fff;
  font-size: 70px;
  line-height: 70px;
  font-weight: 700;
  letter-spacing: 0px;
  font-family: 'Urbanist', sans-serif;
  margin-bottom: 15px;
}

.google_font {
  margin-bottom: 15px;
}

.column_attr.align_center {
  text-align: center;
}

.demo_subtitle {
  color: #fff;
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  letter-spacing: 0px;
  font-family: 'Urbanist', sans-serif;
}

.quick_fact .number-wrapper {
  font-size: 90px;
  line-height: 1.3em;
}

.quick_fact .number-wrapper .number {
  color: #ef5068;
}

.title_tag {
  font-size: 25px;
  line-height: 29px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #fff;
  font-family: 'Urbanist', sans-serif;
}

.demo_h2 {
  color: #022988;
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  letter-spacing: 0px;
  font-family: 'Urbanist', sans-serif;
}

.align_center {
  text-align: center;
}

.themecolor {
  color: #D2F64F;
}

hr.no_line,
.dark hr.no_line {
  background: none;
  color: transparent;
}


img.scale_with_grid,
#Content img {
  max-width: 600px;
  margin-top: 20px;
  height: auto;
}

img.host_details_main_image {
  max-height: 410px;
  width: 100%;
  border-radius: 10px;
}

img.host_details_sub_image {
  max-height: 200px;
  width: 100%;
  border-radius: 10px;
}

.one-second.mcb-wrap {
  width: 50%;
}

.row,
.clearfix {
  zoom: 1;
}

.mcb-wrap {
  float: left;
  position: relative;
  z-index: 1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.page_title_demo {
  z-index: 11;
  font-family: "DM Serif Display";
  visibility: visible;
  text-align: center;
  line-height: 50px;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 60px;
  border-color: rgb(128, 122, 115);
  border-style: none;
  margin: 0px;
  border-radius: 0px;
  padding: 0px;
  color: rgb(159, 212, 78);
  text-decoration: none;
  white-space: normal;
  /* width: 465px; */
  height: auto;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform-origin: 50% 50% 0px;
}



.title_small {
  z-index: 10;
  font-family: Montserrat;
  visibility: visible;
  text-align: left;
  line-height: 23px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 14px;
  border-color: rgb(128, 122, 115);
  border-style: none;
  margin: 0px;
  border-radius: 0px;
  padding: 0px;
  color: rgb(102, 97, 92);
  text-decoration: none;
  white-space: nowrap;
  width: auto;
  height: auto;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform-origin: 50% 50% 0px;
}

.button-style-test {
  z-index: 13;
  padding: 12px !important;
  background-color: rgb(159, 212, 78);
  font-family: Montserrat;
  visibility: visible;
  text-align: left;
  line-height: 39px;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 10px;
  border-color: rgba(255, 255, 255, 0);
  border-style: none;
  margin: 0px;
  border-radius: 25px;
  padding: 0px 23px;
  color: rgb(255, 255, 255);
  text-decoration: none;
  white-space: nowrap;
  width: auto;
  height: auto;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform-origin: 50% 50% 0px;
  border-width: 0px;
}

.block_classname {
  z-index: 15;
  background-color: rgba(0, 0, 0, 0);
  visibility: visible;
  overflow: hidden;
  border-color: rgb(229, 219, 207);
  border-style: solid;
  border-width: 0px 0px 1px 1px;
  margin: 0px;
  border-radius: 0px;
  padding: 0px;
  color: rgb(0, 0, 0);
  width: 147px;
  height: 47px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform-origin: 50% 50% 0px;
}

.block_book_visit {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-around;
}

.inner_white_block {
  background: white;
  float: left;
  position: relative;
  padding: 50px;
  border-radius: 10px;
  z-index: 1;
  box-sizing: border-box;
}

.ant-row {
  justify-content: space-around;
  /* padding: 10px; */
}

.sidebar {
  background-color: rgb(35 55 75 / 90%);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}

.map-container {
  min-height: 1000px;
  width: 100%;
  /* or specific width in pixels */
}

/* .mapboxgl-popup {
  min-width: 350px !important;
  max-width: 390px;
} */

.mapboxgl-popup-close-button {
  font-size: 25px;
}

.mapboxgl-cluster {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: rgba(255, 0, 0, 0.5);
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
}

.scroll {
  margin: 4px, 4px;
  padding: 0px;
  width: 400px;
  /* height: 810px; */
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
}

@media (min-width: 1171px) {
  .filter-icons-box {
    position: fixed;
    top: 72px;
    z-index: 99;
    background-color: rgb(56 14 14 / 70%);
    padding: 10px;
    min-width: 400px
  }

  .filter-list-box {
    max-height: 700px;
    position: fixed;
    top: 220px;
    left: 0%;
    z-index: 99;
    background-color: rgb(156 147 149 / 70%);
    overflow: scroll;
  }
}

@media (max-width: 1170px) and (min-width: 768px) {
  .filter-icons-box {
    position: fixed;
    top: 72px;
    z-index: 99;
    background-color: rgb(9 197 235 / 70%);
    padding: 10px;
    min-width: 400px
  }

  .filter-list-box {
    max-height: 700px;
    position: fixed;
    top: 185px;
    left: 0%;
    z-index: 99;
    background-color: rgb(156 147 149 / 70%);
    overflow: scroll;
  }
}

@media (max-width: 767px) {
  .filter-icons-box {
    position: fixed;
    top: 72px;
    z-index: 99;
    background-color: rgb(9 197 235 / 70%);
    padding: 10px;
    min-width: 400px
  }

  .filter-list-box {
    max-height: 600px;
    position: fixed;
    top: 160px;
    left: 0%;
    z-index: 99;
    background-color: rgb(156 147 149 / 70%);
    overflow: scroll;
  }
}

/* Add this to your CSS */
.map-marker {
  background-color: rgb(6, 105, 17);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  cursor: pointer;
}



.cluster-marker {
  color: #fff;
  background: #0f0b03;
  border-radius: 50%;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crime-marker {
  background: none;
  border: none;
}

.crime-marker img {
  width: 25px;
}

.overlay {
  /* filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0); */
  opacity: 0;
  transition: .4s;
  background-color: rgba(0, 0, 0, .7);
  height: 88%;
  left: 0;
  position: absolute;
  top: 15px;
  text-align: center;
  width: 100%;
}

.overlay:hover,
.infobox .inner .image .overlay:hover .wrapper hr {
  opacity: 1;
}

.mapboxgl-popup-content {
  padding: 0px 0px 0px !important;
  width: 295px;
}

.mapboxgl-popup-close-button {
  top: -8px !important;
}

.mapboxgl-popup {
  max-width: 300px;
  /* Adjust the maximum width as needed */
  font-size: 14px;
  /* Adjust the font size as needed */
}

.mapboxgl-popup-content {
  padding: 10px;
  /* Add padding to the content for better spacing */
}

.mapboxgl-popup-close-button {
  display: none;
  /* Hide the close button for mobile devices */
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-color: transparent transparent #fff transparent;
  /* Set the border color of the popup tip */
}


@media (max-width: 520px) {
  .mobile-footer-font {
    font-size: 10px !important;
    display: flex;
  }
}

@media (max-width: 767px) {
  .float-right-footer {
    float: left !important;
    font-size: 14px;
  }

  .housing-page-image {
    height: 230px !important;
  }
}

.float-right-footer {
  float: right !important;
}

.ant-btn-primary {
  border-color: #4ba183 !important;
  /* background: #008455 !important; */
}

@media (max-width: 1100px) {
  .athelete_middle_section {
    width: 100%;
    overflow-x: hidden;
    height: 100%;
    max-height: 650px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../img/GridBkg.png);
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px !important;
    display: inline-flex;
    /* background:  linear-gradient(180deg, #335924 0%, #0A2D47 100%) */
  }

  .scale-with-grid {
    height: 410px !important;
  }
}

@media (min-width: 1101px) {
  .athelete_middle_section {
    width: 100%;
    overflow-x: hidden;
    height: 100%;
    max-height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../img/GridBkg.png);
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    /* background:  linear-gradient(180deg, #335924 0%, #0A2D47 100%) */
  }
}


@media (max-width: 1100px) {
  .host_middle_section {
    width: 100%;
    overflow-x: hidden;
    height: 100%;
    /* max-height: 700px; */
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../img/BKG2.png);
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    /* background:  linear-gradient(180deg, #335924 0%, #0A2D47 100%) */
  }
}

@media (min-width: 1101px) {
  .host_middle_section {
    width: 100%;
    overflow-x: hidden;
    height: 100%;
    /* max-height: 650px; */
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../img/BKG2.png);
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    /* background:  linear-gradient(180deg, #335924 0%, #0A2D47 100%) */
  }
}

.host_bottom_section {
  width: 100%;
  overflow-x: hidden;
  height: 100%;
  padding: 30px 0px !important;
  /* max-height: 650px; */
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../img/BKGImageBottom.png);
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}


grey-btn {
  color: white !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.grey-btn:hover {
  color: black !important;
  background-color: white !important;
  border-color: #545b62;
}